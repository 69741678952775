/**
 * nike custom dialog component
 * customised modal dialog with an optional title and actions
 * @exports NikeDialog of @type {ReactComponent}
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import './styles.scss';

interface Props {
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  title?: Object;
  renderContent: Object;
  renderActions?: Object;
  dividers?: boolean;
  open?: any;
  // paperStyle?: string;
  className?: string;
  // paperStyle: Function;
}

export default function NikeDialog(props: Props) {
  const {
    disableBackdropClick,
    disableEscapeKeyDown,
    title,
    open,
    renderContent,
    renderActions,
    dividers,
    // paperStyle,
    className,
    ...otherProps
  } = props;

  const getTitle = () => (title ? (
    <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
  ) : null);

  const getActions = () => (renderActions ? (
    <DialogActions>
      {renderActions}
    </DialogActions>
  ) : null);

  return (
    <Dialog
      // paperStyle={paperStyle}
      className={`${className || ''} nikeDialogRoot`}
      classes={{
        root: 'dialog-root',
        scrollPaper: 'dialog-scrollPaper',
        paper: 'paperStyle'
      }}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth="lg"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...otherProps}
    >
      {getTitle()}
      <DialogContent dividers={dividers}>
        {renderContent}
      </DialogContent>
      {getActions()}
    </Dialog>
  );
}
